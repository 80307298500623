@import '../../variables.module.scss';

.work-item-container {
	// background-color: aqua;
	// flex: 1;
	display: flex;
	// grid-auto-flow: column;
	// grid-auto-columns: min-content;
	// width: 500px;

	// align-items: flex-start;
	// align-items: ;
	// height: 100%;
	justify-content: center;
	flex-wrap: wrap;

	.work-item {
		border-style: solid;
		border-color: $border-color;
		border-width: 1px;
		border-bottom: 0;
		border-right: 0;
		border-left: 0;

		border-radius: 6px;

		margin: 20px;
		width: 20rem;
		height: 15rem;

		background-color: $theme-dark-color;
		overflow: hidden;
	}

	.work-item-img {
		// position: absolute;
		// object-fit: cover;
		object-fit: cover;
		// width: 20rem;
		// width: 100%;
		height: inherit;
	}
}

.gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.gallery-item {
	display: flex;
	flex-direction: column;
	// align-items: center;
	justify-content: space-between;

	// align-content: center;
	// align-items: center;
	// justify-items: ;

	border-style: solid;
	border-color: $border-color;
	border-width: 1px;
	border-bottom: 0;
	border-right: 0;
	border-left: 0;
	border-radius: 6px;

	background-color: $theme-dark-color;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);

	margin: 1rem;
	overflow: hidden;
	width: 25rem;
	height: 15rem;
}

.gallery-text {
	z-index: 1;
	padding-top: 4px;
	padding-left: 10px;
	font-weight: 600;
	font-size: 16px;
}

.gallery-image-container {
	flex: 1;
	margin-top: 10px;
}

.gallery-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 400ms ease-out;
}

.gallery-image:hover {
	transform: scale(1.1);
}
