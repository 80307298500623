@import '../../variables.module.scss';

.top-container {
	// background-color: #0f0;
	display: flex;
	justify-content: space-evenly;
	flex-direction: row;
	flex-wrap: wrap;

	border-color: #fff;
	border-width: 20px;

	.profile-content {
		min-width: 300px;
		flex: 1;

		.profile-text {
			text-align: center;
			padding-top: 15px;
			font-weight: 900;
			letter-spacing: 2px;
		}

		.profile-email {
			font-weight: 400;
			letter-spacing: 0px;
			font-style: italic;
			font-size: 1rem;
			color: rgba(255, 255, 255, 0.4);
		}

		.center-items {
			// background-color: #0f0;
			display: flex;
			flex-direction: column;
			// justify-: center;
			align-items: center;

			.center-items-inner {
				// background-color: #0f0;
				border-style: solid;
				border-color: $border-color;
				border-color: $theme-less-red-color;
				border-width: 2px;
				border-radius: 6px;

				// padding: 2px;
				padding-top: 2px;
				padding-bottom: 2px;
				padding-left: 10px;
				padding-right: 10px;
				margin-top: 15px;
				span {
					// background-color: #0f0;
					display: flex;
					align-items: center;
					// justify-content: center;

					svg {
						// background-color: #0f0;
						// color: rgba(255, 255, 255, 0.7);
						// color: $border-color;
						// padding-left: 30px;
						// padding: 2px;
						padding-right: 10px;
						width: 20px;
						height: 20px;
					}
				}
			}
		}

		.profile-items {
			// background-color: rgba(1, 1, 1, 0.4);
			list-style: none;
			padding-bottom: 0px;
			margin-bottom: 0px;
			margin-top: 10px;
			margin-left: 20px;

			li > span {
				// padding-top: 5px;
				// background-color: rgba(1, 1, 1, 0.4);
				// border-radius: 6px;
				// margin-bottom: 6px;

				// width: auto;
				padding-bottom: 2px;
				width: 300px;

				display: flex;

				// align-content: center;
				align-items: center;
				// padding-left: 10px;
				// text-align: center;
				// background-color: #0f0;
				// border-
			}
			svg {
				// background-color: #0f0;
				// color: rgba(255, 255, 255, 0.7);
				// color: $border-color;
				// padding-left: 30px;
				// padding: 2px;
				padding-right: 10px;
				width: 25px;
				height: 25px;
			}
		}
	}

	.profile-container {
		display: grid;
		padding-top: 20px;
		padding-left: 20px;
	}

	.profile-circle {
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 50%;
		width: 150px;
		height: 150px;

		border: 2px;
		border-color: $theme-less-red-color;
		border-style: solid;

		// position: absolute;
		// bottom: 0;
		align-self: flex-end;

		grid-column: 1;
		grid-row: 1;
	}

	.profile-img {
		// position: absolute;
		// background-color: #fff;
		// border-width: 10px;
		border-radius: 43%;
		padding-bottom: 2px;
		// scale: 0.5;
		// max-width: 150px;
		// max-height: 300px;
		width: 150px;
		height: auto;
		align-self: flex-end;
		// display: inline-block;
		// bottom: 0;

		// height: 100px;

		transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
		transform-origin: bottom;

		grid-column: 1;
		grid-row: 1;

		// clip-path: path('M 390,400 C 390,504.9341 304.9341,590 200,590 95.065898,590 10,504.9341 10,400 V 10 H 200 390 Z');
	}

	.profile-img:hover {
		transform: scale(1.1);
	}

	.container-tags {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		// justify-content: space-between;
		justify-content: center;
		align-items: center;

		// border-top: 1px;
		border-left: 0px;
		border-right: 0px;
		border-bottom: 0px;
		// border-style: solid;
		// border-color: $theme-less-red-color;

		// margin-left: 20px;
		// margin-right: 20px;
		// margin-top: 15px;
		padding-top: 15px;

		padding-right: 20px;
		padding-left: 20px;
	}
}
