@import '../../variables.module.scss';

.profile-social {
	margin-top: 5px;
	padding-top: 5px;

	border-style: solid;
	border-color: $border-color;
	border-width: 1px;
	border-bottom: 0;
	border-right: 0;
	border-left: 0;

	display: flex;
	justify-content: center;

	.social-bar {
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 360px;
		padding-top: 5px;
		padding-bottom: 5px;
		background-color: $theme-dark-color;
		border-radius: 6px;
	}

	.link {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.social-icon {
		color: $theme-less-red-color;

		&:hover {
			scale: 1.2;
		}
		&:active {
			color: #fff;
		}
	}
}

.dfn {
	// background: #e9e9e9;
	// border-bottom: dashed 1px rgba(0, 0, 0, 0.8);
	// padding: 0 0.4em;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	// font-style: normal;
	position: relative;
}
.dfn::after {
	content: attr(data-info);
	display: inline;
	position: absolute;
	top: 22px;
	left: 0;
	opacity: 0;
	// width: 120px;
	width: max-content;
	font-size: 13px;
	font-weight: 700;
	line-height: 1.5em;
	padding: 0.5em 0.8em;
	background: $theme-blue-color;

	color: $white;
	pointer-events: none; /* This prevents the box from apearing when hovered. */
	transition: opacity 250ms, top 250ms;

	border-radius: 6px;
}
.dfn::before {
	content: '';
	display: block;
	position: absolute;
	top: 12px;
	left: 10px;
	opacity: 0;
	width: 0;
	height: 0;
	border: solid transparent 5px;
	border-bottom-color: $theme-blue-color;

	transition: opacity 250ms, top 250ms;
}
.dfn:hover {
	z-index: 2;
} /* Keeps the info boxes on top of other elements */
.dfn:hover::after,
.dfn:hover::before {
	opacity: 1;
}
.dfn:hover::after {
	top: 30px;
}
.dfn:hover::before {
	top: 20px;
}
