@import '../../variables.module.scss';

$color: $theme-red-color;
$spread: 10px;
$blur: 78px;

::-webkit-scrollbar {
	width: 6px;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: $scrollbar-bg;
	border-radius: 10px;
}

.header-title {
	display: flex;
	align-items: center;
	height: 100%;
	color: $color;
	font-family: 'Roboto Slab', sans-serif;
	letter-spacing: 10px;
	font-weight: 900;
	font-size: 2rem;
	padding-left: 20px;
}

.header-links {
	display: flex;
	justify-content: flex-end;

	.clickable-link {
		color: $theme-less-red-color;
		padding-right: 20px;

		> svg:hover {
			scale: 1.2;
		}
		:active {
			color: $white;
		}
	}
}

.content-border {
	position: absolute;
	z-index: -1;

	display: flex;
	justify-content: center;
	align-items: center;
	.content-border-flicker {
		animation: border-flicker 2s linear infinite;
		height: 100%;
		width: 100%;
		border-radius: 20px;

		margin-left: 20px;
		margin-right: 20px;
	}
}

.content-container {
	background-color: $theme-bg-color;
	backdrop-filter: blur(10px);

	margin-left: 20px;
	margin-right: 20px;

	display: flex;
	flex-direction: column;
	border-radius: 20px;

	.content-header {
		// background-color: rgba(255, 0, 118, 0.2);
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 65px;

		border-bottom: 1px;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border-color: $border-color;
		border-style: solid;
	}

	.content-container-inner {
		flex: 1;
		margin-bottom: 15px;
		overflow-y: auto;
	}
}

@keyframes border-flicker {
	0% {
		opacity: 0.1;
		-webkit-box-shadow: 0px 0px $blur $spread $color;
		-moz-box-shadow: 0px 0px $blur $spread $color;
		box-shadow: 0px 0px $blur $spread $color;
	}
	2% {
		opacity: 1;
		-webkit-box-shadow: 0px 0px $blur $spread $color;
		-moz-box-shadow: 0px 0px $blur $spread $color;
		box-shadow: 0px 0px $blur $spread $color;
	}
	4% {
		opacity: 0.1;
		-webkit-box-shadow: 0px 0px $blur $spread $color;
		-moz-box-shadow: 0px 0px $blur $spread $color;
		box-shadow: 0px 0px $blur $spread $color;
	}

	8% {
		opacity: 1;
		-webkit-box-shadow: 0px 0px $blur $spread $color;
		-moz-box-shadow: 0px 0px $blur $spread $color;
		box-shadow: 0px 0px $blur $spread $color;
	}
	70% {
		opacity: 0.7;
		-webkit-box-shadow: 0px 0px $blur $spread $color;
		-moz-box-shadow: 0px 0px $blur $spread $color;
		box-shadow: 0px 0px $blur $spread $color;
	}
	100% {
		opacity: 1;
		-webkit-box-shadow: 0px 0px $blur $spread $color;
		-moz-box-shadow: 0px 0px $blur $spread $color;
		box-shadow: 0px 0px $blur $spread $color;
	}
}
