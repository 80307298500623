// @import url('https://fonts.googleapis.com/css2?family=PT+Sans+Caption&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Roboto+Flex');
// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

// @import url('https://fonts.cdnfonts.com/css/share-tech');
@import url('https://fonts.cdnfonts.com/css/roboto-condensed');
@import url('https://fonts.cdnfonts.com/css/roboto-slab-2');
@import './variables.module.scss';

html,
body {
	overflow: hidden;
}

body {
	margin: 0;
	height: -webkit-fill-available;
	min-height: 100vh;
	position: relative;

	// font-family: 'PT Sans Caption', sans-serif;
	// font-family: 'Audiowide', sans-serif;
	// font-family: 'Share Tech', sans-serif;

	font-family: 'Roboto Condensed Light', sans-serif;
	// font-weight: lighter;

	font-size: 1.2em;
	color: $white;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
