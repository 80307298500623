.container {
	display: flex;
	height: 100%;

	justify-content: center;
	align-items: center;

	// background-color: #00f;

	.loaderIcon {
		margin-right: 7px;
		// top: 2px;
		// background-color: #0f0;
		width: 30%;
		height: 30%;
		animation: spin 1s infinite linear;
	}

	@keyframes spin {
		from {
			transform: scale(1) rotate(0deg);
		}
		to {
			transform: scale(1) rotate(360deg);
		}
	}
}
