@import '../../variables.module.scss';

.work-download-container {
	position: absolute;
	top: 20px;
	left: 30px;

	display: flex;
	flex-direction: column;
	justify-content: center;

	.work-link-container {
		background-color: rgba(0, 0, 0, 0.3);
		padding: 4px;
		padding-right: 8px;
		padding-left: 8px;
		font-size: smaller;

		border-radius: 6px;

		margin: 4px;
		color: $white;

		&:hover {
			background-color: rgba(0, 0, 0, 1);
		}

		&:active {
			color: $theme-red-color;
		}
	}
}

.work-container {
	position: relative;

	.react-pdf__Document {
		justify-content: center;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.pdf-page {
		border-color: #000;
		margin: 20px;

		box-shadow: 10px 10px 14px -1px rgba(0, 0, 0, 0.75);
		-webkit-box-shadow: 10px 10px 14px -1px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 10px 10px 14px -1px rgba(0, 0, 0, 0.75);
	}

	.react-pdf__Page__canvas {
		height: auto !important;
	}
}

div.document-mobile {
	/* Make sure pdf page does not go out of bounds, when in mobile. */
	.react-pdf__Page__canvas {
		width: 100% !important;
		height: auto !important;
	}
}
