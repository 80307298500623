.bg-container {
	height: 130vmax;
	width: 130vmax;
	position: absolute;
	z-index: -1;

	background: url('bg.jpg') no-repeat;
	background-size: cover;
	background-position: center center;
}
