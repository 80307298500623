@import 'variables.module.scss';

.center-container {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.image-ref {
	background-color: $theme-bg-color;

	position: absolute;
	bottom: 0;
	right: 0;

	padding-top: 4px;
	padding-bottom: 4px;
	padding-right: 8px;
	padding-left: 8px;
	margin: 2px;

	border-radius: 8px;
	// backdrop-filter: blur(10px);

	color: rgba(255, 255, 255, 0.3);

	a {
		color: rgba(255, 255, 255, 0.3);

		& :hover {
			color: #000;
		}
	}
}
